<template>
    <div>
      	<div class="row mx-md-4">
			<!-- Recomended Product -->
			<div class="col-md-6 mb-4">
				<div class="card round shadow border-0">
					<div class="card-body p-4">
						<h5 class="mb-0 mt-2 pl-4"><i class="far fa-check-square fa-lg"></i> &nbsp;Produk Pilihan</h5>
						<div class="carousel-wrap w-100">
							<div class="text-center my-3" v-if="isLoading">
    						  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    						</div>
							<div class="owl-carousel owl-product-6 pt-3 pb-1 px-2">
								<div class="item card product" v-for="(product, index) in top_products" :key="index">
									<router-link :to="'/product-detail/'+product.prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
										<div class="d-flex align-items-center" style="height: 170px; overflow: hidden;">
											<img :data-src="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top owl-lazy" :alt="product.prod_image" />
										</div>
										<div class="card-body p-2 text-center">
											
											<div class="d-inline-flex justify-content-center align-items-center" v-for="index2 in 5" :key="index2">
												<i class="fa fa-star fa-sm text-warning" v-if="index2 <= product.prod_rating"></i>
												<div class="fa-stack fa-sm" style="height: 16px; line-height: 22px; width: 15px;" v-else-if="index2 - 0.5 <= product.prod_rating">
													<i class="fa-stack-1x fa fa-star text-secondary"></i>						
													<i class="fa-stack-1x fa fa-star-half text-warning"></i>						
												</div>
												<i class="fa fa-star fa-sm text-secondary" v-else></i>
											</div>
													
											<p class="card-text text-dark mb-0 d-flex justify-content-center" style="height:50px; overflow: hidden;">{{ product.prod_name | str_limit(25) | capitalize }}</p>
											<p class="text-muted mb-0 text-truncate">{{ product.prod_summary }}</p>
											<!-- <div v-if="product.prod_discount && product.prod_discount != 0">
												<p class="text-success mb-0">{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency('IDR') }}</p>
												<label class="d-flex justify-content-center align-items-center">
													<small class="bg-danger text-white round px-2">{{ product.prod_discount }}%</small> 
													<strike class="text-muted pl-1">{{ product.prod_price | formatCurrency('IDR') }}</strike>
												</label>
											</div>
											<p class="text-success my-3" v-else>{{ product.prod_price | formatCurrency('IDR') }}</p> -->
											<p class="text-success my-3">-</p>
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- New Product -->
			<div class="col-md-6 mb-4">
				<div class="card round shadow border-0">
					<div class="card-body p-4">
						<h5 class="mb-0 mt-2 pl-4"><i class="far fa-plus-square fa-lg"></i> &nbsp;Produk Terbaru</h5>
						<div class="carousel-wrap w-100">
							<div class="text-center my-3" v-if="isLoading">
    						  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    						</div>
							<div class="owl-carousel owl-product-6 pt-3 pb-1 px-2">
								<div class="item card product" v-for="(product, index) in new_products" :key="index">
									<router-link :to="'/product-detail/'+product.prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
										<div class="d-flex align-items-center" style="height: 170px; overflow: hidden;">
											<img :data-src="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top owl-lazy" :alt="product.prod_image" />
										</div>
										<div class="card-body p-2 text-center">
											
											<div class="d-inline-flex justify-content-center align-items-center" v-for="index2 in 5" :key="index2">
												<i class="fa fa-star fa-sm text-warning" v-if="index2 <= product.prod_rating"></i>
												<div class="fa-stack fa-sm" style="height: 16px; line-height: 22px; width: 15px;" v-else-if="index2 - 0.5 <= product.prod_rating">
													<i class="fa-stack-1x fa fa-star text-secondary"></i>						
													<i class="fa-stack-1x fa fa-star-half text-warning"></i>						
												</div>
												<i class="fa fa-star fa-sm text-secondary" v-else></i>
											</div>
											
											<p class="card-text text-dark mb-0 d-flex justify-content-center" style="height:50px; overflow: hidden;">{{ product.prod_name | str_limit(25) | capitalize }}</p>
											<p class="text-muted mb-0 text-truncate">{{ product.prod_summary }}</p>
											<!-- <div v-if="product.prod_discount && product.prod_discount != 0">
												<p class="text-success mb-0">{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency('IDR') }}</p>
												<label class="d-flex justify-content-center align-items-center">
													<small class="bg-danger text-white round px-2">{{ product.prod_discount }}%</small> 
													<strike class="text-muted pl-1">{{ product.prod_price | formatCurrency('IDR') }}</strike>
												</label>
											</div>
											<p class="text-success my-3" v-else>{{ product.prod_price | formatCurrency('IDR') }}</p> -->
											<p class="text-success my-3">-</p>
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    	</div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'HomeMiddle',
  	data () {
 		return {
			new_products: [],
			top_products: []
 		}
	},
	computed: {
		isLoading() {
			return this.$store.getters.isLoading
      	},
	},
    created () {
		window.axios.get('/home/tagged-product')
 		.then((response) => {
			this.new_products = response.data.data.new_product
			this.top_products = response.data.data.top_rated

			Vue.nextTick(function(){
				$('.owl-product-6').owlCarousel({
					lazyLoad:true,
					loop: true,
					margin: 10,
					nav: false,
					autoplay: true,
					autoplayHoverPause: true,
					responsive: {
						0: {
							items: 2
						},
						600: {
							items: 2
						},
						1000: {
							items: 3
						}
					}
				});
    		});
 		})
 		.catch((error) => {
 			console.log(error)
		})
    }
}

</script>