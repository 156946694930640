<template>
    <div>
		<div class="row mx-md-4">
			
            <!-- Manufacturer List -->
			<div class="col-md-12 mb-4">
				<div class="row round shadow py-4 px-3 m-0 bg-white align-items-center">
					<div class="col-lg-3">
					</div>
					<div class="col-lg-9 px-1">
						<div class="row m-0">
							<div class="col-md-12 p-0">
								<div class="label-category mb-3 w-100 d-inline-flex justify-content-between align-items-center" style="background-color: royalblue;">
									<h5 class="my-2 text-light"><i class="fa fa-flag fa-sm mr-2"></i>Brand</h5>
									<router-link to="/brand" class="d-none d-sm-block d-md-block d-lg-block">
										<button class="btn btn btn-outline-light"><small>Lihat lebih banyak</small></button>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-12">
						<div class="row m-0">
                			<div class="col-md-12 p-0">
								<div class="carousel-wrap w-100 px-5">
									<div class="text-center my-3" v-if="isLoading">
										<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
									</div>
									<div class="owl-carousel owl-manufacture-2 text-center">
										<div class="item card product p-1" v-for="manufacture in manufactures" :key="manufacture.brand_id">
											<router-link :to="'/product-list?type=product&q='+ manufacture.brand_name" class="text-decoration-none">
												<div class="d-flex align-items-center" style="height: 150px; overflow: hidden;">
													<img :data-src="manufactureImage(manufacture.brand_image)" @error="imageLoadError" class="card-img-top owl-lazy" :alt="manufacture.brand_image" />
												</div>
											<h6 style="max-height: 20px; overflow: hidden;" class="card-text text-secondary mb-0">{{ manufacture.brand_name | str_limit(10) }}</h6>
											<p class="card-text text-secondary mb-0 pb-4">{{ manufacture.total_product }} Produk</p>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    	</div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'HomeMiddle2',
  	data () {
 		return {
			manufactures: []
 		}
	},
	computed: {
		isLoading() {
			return this.$store.getters.isLoading
      	},
	},
    created () {
 		window.axios.get('/home/brand')
 		.then((response) => {
			this.manufactures = response.data.data
			
			Vue.nextTick(function(){
				$('.owl-manufacture-2').owlCarousel({
					lazyLoad:true,
					loop: true,
					margin: 10,
					nav: true,
					navText: [
						'<i class="fa fa-chevron-left" aria-hidden="true"></i>',
						'<i class="fa fa-chevron-right" aria-hidden="true"></i>'
					],
					autoplay: true,
					autoplayHoverPause: true,
					responsive: {
						0: {
							items: 1
						},
						600: {
							items: 3
						},
						1000: {
							items: 6
						}
					}
        		});
        	});
 		})
 		.catch((error) => {
 			console.log(error)
		})
    }
}

</script>