<template>
  	<div>
    	<main class="container-fluid">
			<HomeTop />
			<HomeMiddle />
			<HomeMiddle2 />
			<HomeBottom />
    	</main>
  	</div>
</template>

<script>
import HomeTop from '@/components/HomeTop.vue'
import HomeMiddle from '@/components/HomeMiddle.vue'
import HomeMiddle2 from '@/components/HomeMiddle2.vue'
import HomeBottom from '@/components/HomeBottom.vue'

export default {
	components: {
  		HomeTop, HomeMiddle, HomeMiddle2, HomeBottom
	},
	created() {
		$(document).scrollTop(0)

		if(this.$store.getters.isLoggedIn && this.$session.exists() && this.$session.has('product_keranjang')) {
			let formData = new FormData();
			formData.append('prod_id', this.$session.get('product_keranjang')[0].prod_id)
			formData.append('prod_qty', this.$session.get('product_keranjang')[0].prod_qty)

			window.axios.post('/cart', formData, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						"token": this.$store.getters.token_member
					}
				}
			)
			.then((response) => {
				window.NavbarDirtyAccess.get_productKeranjang()
				this.$router.push('/member/shopping-cart')
			})
			.catch((error) => {
				console.log(error)
			})
		}

		this.$session.remove('product_keranjang')
	}  
}
</script>