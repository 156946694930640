<template>
  <div style="position: relative;">
    <div class="container-top-carousel">
      <!-- Top Carousel/Slider  -->
      <div class="top-carousel">
        <div class="carousel-wrap w-100 w-lg-50 w-xl-50">
          <div class="owl-carousel owl-top-carousel">
            <div class="item image-carousel" v-for="banner in banners" :key="banner.banner_order">
              <router-link :to="parse_url(banner.banner_url)" class="text-decoration-none">
                <img :data-src="bannerImage(banner.banner_image)" @error="imageLoadError" class="card-img-top owl-lazy" :alt="banner.banner_image" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div style="position: relative; top: -22px;">
        <!-- Promo duniamurah -->
        <div class="d-flex justify-content-center">
          <div class="w-xl-50 w-lg-50 w-100 px-3 py-2">
            <div class="row container mx-0 round bg-primary border border-white text-white shadow d-flex align-items-center py-2">
              <div class="col-md-6 text-center">
                <router-link to="/promo" class="text-decoration-none text-white">
                  <h4 class="my-1"><i class="fa fa-star"></i> Promo Duniamurah</h4>
                </router-link>
              </div>
              <div class="col-md-3 offset-md-3 text-right">
                <router-link to="/promo" class="btn btn-outline-light w-100 my-1">
                  Lihat Promo
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Kelebihan duniamurah  -->
        <div class="d-flex justify-content-center">
          <div class="w-xl-50 w-lg-50 w-100 px-3">
            <div class="carousel-wrap w-100">
              <div class="owl-carousel owl-top-carousel-2">
                <div class="item card round">
                  <router-link to="/static/kelebihan-kami" class="text-decoration-none">
                    <img src="@/assets/images/kelebihan-1.jpg" class="card-img-top round" alt="staticImage" />
                    <div class="card-body pb-2 pt-0 px-1 text-center">
                      <p class="card-text text-secondary mb-0">Bebas Ongkir!</p>
                    </div>
                  </router-link>
                </div>
                <div class="item card round">
                  <router-link to="/static/kelebihan-kami" class="text-decoration-none">
                    <img src="@/assets/images/kelebihan-2.jpg" class="card-img-top round" alt="staticImage" />
                    <div class="card-body pb-2 pt-0 px-1 text-center">
                      <p class="card-text text-secondary mb-0">Harga Nego!</p>
                    </div>
                  </router-link>
                </div>
                <div class="item card round">
                  <router-link to="/static/kelebihan-kami" class="text-decoration-none">
                    <img src="@/assets/images/kelebihan-3.jpg" class="card-img-top round" alt="staticImage" />
                    <div class="card-body pb-2 pt-0 px-1 text-center">
                      <p class="card-text text-secondary mb-0">Spesial Promo!</p>
                    </div>
                  </router-link>
                </div>
                <div class="item card round">
                  <router-link to="/static/kelebihan-kami" class="text-decoration-none">
                    <img src="@/assets/images/kelebihan-4.jpg" class="card-img-top round" alt="staticImage" />
                    <div class="card-body pb-2 pt-0 px-1 text-center">
                      <p class="card-text text-secondary mb-0">Mudah & Cepat!</p>
                    </div>
                  </router-link>
                </div>
                <div class="item card round">
                  <router-link to="/static/kelebihan-kami" class="text-decoration-none">
                    <img src="@/assets/images/kelebihan-5.jpg" class="card-img-top round" alt="staticImage" />
                    <div class="card-body pb-2 pt-0 px-1 text-center">
                      <p class="card-text text-secondary mb-0">Kemudahan B2B!</p>
                    </div>
                  </router-link>
                </div>
                <div class="item card round">
                  <router-link to="/kelebihan-kami" class="text-decoration-none">
                    <img src="@/assets/images/kelebihan-6.jpg" class="card-img-top round" alt="staticImage" />
                    <div class="card-body pb-2 pt-0 px-1 text-center">
                      <p class="card-text text-secondary mb-0">Kemudahan B2C!</p>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block">
      <div class="row mx-md-4 mb-4">
        <!-- Category produk -->
        <div class="col-md-4 col-lg-3">
          <div class="card round shadow">
            <div class="card-body">
              <h6 class="text-center py-2"><i class="fa fa-align-left fa-lg"></i> &nbsp; &nbsp;<strong>Kategori Produk</strong></h6>
              <hr />
              <ul class="list-group list-group-flush category-list py-2">
                <template v-for="(category, index) in categories">
                  <li class="list-group-item py-3" v-if="index < 7" :key="index">
                    <router-link :to="'/product-list?type=category&q=' + category.cat_id + '-' + category.cat_name.toLowerCase()" class="product-category border-0 d-flex justify-content-between align-items-center">
                      <span class="text-truncate"><i :class="'fa ' + category.cat_icon + ' mr-2'"></i> &nbsp; {{ category.cat_name | capitalize }}</span>
                      <i class="fa fa-chevron-right"></i>
                    </router-link>
                    <div class="sub-category-list py-1 px-4">
                      <div class="row">
                        <div class="col-md-4 text-left my-3">
                          <router-link :to="'/product-list?type=category&q=' + category.cat_id + '-' + category.cat_name.toLowerCase()" class="product-category">
                            <strong>{{ category.cat_name | capitalize }}</strong>
                          </router-link>
                          <div class="text-right w-50"><hr class="my-2" /></div>
                          <ul class="pl-1">
                            <li class="list-group-item border-0 p-0" v-for="sub_cat in category.sub_category" :key="sub_cat.cat_id">
                              <router-link :to="'/product-list?type=category&q=' + sub_cat.cat_id + '-' + sub_cat.cat_name.toLowerCase()" class="product-category">{{ sub_cat.cat_name | capitalize }}</router-link>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-12 mb-3">
                          <hr class="mt-0 w-75" />
                          <div class="carousel-wrap">
                            <div class="owl-carousel" :id="'owl-manufacture-' + index">
                              <!-- Menampilkan brand berdasarkan kategori -->
                              <div class="item" v-for="merk in category.brand" :key="merk.prod_brand">
                                <!-- <router-link :to="'/product-list?type=product&q='+ merk.brand_name" class="text-decoration-none"> -->
                                <div class="d-flex align-items-center" style="height: 75px; overflow: hidden;">
                                  <img :data-src="manufactureImage(merk.brand_image)" @error="imageLoadError" class="card-img-top owl-lazy" :alt="merk.brand_image" />
                                </div>
                                <!-- </router-link> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>
                <li class="list-group-item py-3" v-if="categories.length > 7">
                  <router-link to="/product-list?type=product&q=Semua Produk" class="product-category border-0 d-flex justify-content-between align-items-center">
                    <span class="text-truncate"><i class="fa fa-plus-circle mr-2'"></i> &nbsp; Lain-lain</span>
                    <i class="fa fa-chevron-right"></i>
                  </router-link>
                  <div class="sub-category-list py-1 px-4">
                    <div class="row">
                      <template v-for="(category, index) in categories">
                        <div class="col-md-4 text-left my-3" v-if="index >= 7" :key="index">
                          <router-link :to="'/product-list?type=category&q=' + category.cat_id + '-' + category.cat_name.toLowerCase()" class="product-category">
                            <strong>{{ category.cat_name | capitalize }}</strong>
                          </router-link>
                          <div class="text-right w-50"><hr class="my-2" /></div>
                          <ul class="pl-1">
                            <li class="list-group-item border-0 p-0" v-for="sub_cat in category.sub_category" :key="sub_cat.cat_id">
                              <router-link :to="'/product-list?type=category&q=' + sub_cat.cat_id + '-' + sub_cat.cat_name.toLowerCase()" class="product-category">{{ sub_cat.cat_name | capitalize }}</router-link>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Rekomendasi produk -->
        <div class="col-md-4 offset-md-4 col-lg-3 offset-lg-6">
          <div class="card round shadow">
            <div class="card-body">
              <h6 class="text-center py-2"><i class="far fa-star fa-lg"></i> &nbsp; &nbsp;<strong>Rekomendasi Produk</strong></h6>
              <hr />
              <div class="text-center my-3" v-if="isLoading">
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <ul class="list-group list-group-flush top-rekomendasi customScroolBar" v-else>
                <li class="list-group-item py-2" v-for="product in best_product" :key="product.prod_id">
                  <router-link :to="'/product-detail/' + product.prod_id + '-' + product.prod_name.toLowerCase()" class="text-decoration-none">
                    <div class="row w-100 m-0 d-flex justify-content-between align-items-center py-1">
                      <div class="col-lg-4 pr-1 pl-0">
                        <img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top" :alt="product.prod_image" />
                      </div>
                      <div class="col-lg-8 text-left px-1">
                        <p class="text-dark mb-0">{{ product.prod_name | str_limit(20) | capitalize }}</p>
                        <p class="text-muted mb-0">{{ product.prod_summary | str_limit(15) }}</p>
                        <!-- <div v-if="product.prod_discount && product.prod_discount != 0">
                          <p class="text-success mb-0">{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency("IDR") }}</p>
                          <small>
                            <small class="bg-danger text-white round px-2">{{ product.prod_discount }}%</small>
                            <strike class="text-muted pl-1">{{ product.prod_price | formatCurrency("IDR") }}</strike>
                          </small>
                        </div>
                        <p class="text-success mb-0" v-else>{{ product.prod_price | formatCurrency("IDR") }}</p> -->
                        <p class="text-success mb-0">-</p>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "HomeTop",
  data() {
    return {
      banners: [],
      categories: [],
      best_product: [],
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    parse_url(banner_url) {
      var url = new URL(banner_url);
      return url.pathname + url.search;
    },
    get_iconCategory(cat_name) {
      if (cat_name == "alat listrik") {
        return "fa-bolt";
      } else if (cat_name == "alat bantu") {
        return "fa-gavel";
      } else if (cat_name == "alat teknik") {
        return "fa-allergies";
      } else if (cat_name == "jasa") {
        return "fa-user-cog";
      } else if (cat_name == "makanan & minuman") {
        return "fa-cookie-bite";
      } else if (cat_name == "health care") {
        return "fa-briefcase-medical";
      } else if (cat_name == "kebutuhan rumah") {
        return "fa-home";
      } else if (cat_name == "makanan ringan") {
        return "fa-ice-cream";
      } else if (cat_name == "bahan masakan") {
        return "fa-cookie-bite";
      } else if (cat_name == "safety & accesories") {
        return "fa-gavel";
      } else if (cat_name == "paket sembako dm") {
        return "fa-cube";
      } else if (cat_name == "konsumable") {
        return "fa-blender";
      } else if (cat_name == "kebutuhan kantor") {
        return "fa-landmark";
      } else if (cat_name == "makanan instan & siap saji") {
        return "fa-cookie";
      } else if (cat_name == "accesories listrik") {
        return "fa-charging-station";
      } else if (cat_name == "kabel") {
        return "fa-project-diagram";
      } else if (cat_name == "komponen instalasi") {
        return "fa-puzzle-piece";
      } else if (cat_name == "komponen panel") {
        return "fa-solar-panel";
      } else if (cat_name == "komponen teknik dengan listrik") {
        return "fa-bolt";
      } else if (cat_name == "komponen teknik dengan mesin") {
        return "fa-hdd";
      } else if (cat_name == "komponen teknik dengan tangan") {
        return "fa-allergies";
      } else if (cat_name == "pneumatik") {
        return "fa-microchip";
      }

      return "fa-gifts";
    },
  },
  created() {
    // BANNER
    window.axios
      .get("/banner")
      .then((response) => {
        this.banners = response.data.data;

        Vue.nextTick(function() {
          $(".owl-top-carousel").owlCarousel({
            lazyLoad: true,
            loop: true,
            margin: 10,
            items: 1,
            nav: false,
            animateOut: "fadeOut",
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: false,
            navText: ['<i class="fa fa-chevron-left text-primary" aria-hidden="true"></i>', '<i class="fa fa-chevron-right text-primary" aria-hidden="true"></i>'],
            responsive: {
              0: {
                items: 1,
              },
              600: {
                items: 1,
              },
              1000: {
                items: 1,
              },
            },
          });
        });
      })
      .catch((error) => {
        console.log(error);
      }),
      // KATEGORI PRODUK
      window.axios
        .get("/category")
        .then((response) => {
          this.categories = response.data.data;

          let temp = [];
          let icon = "";
          let count = 0;

          for (let i = 0; i < response.data.data.length; i++) {
            if (count < 8) {
              icon = this.get_iconCategory(response.data.data[i].cat_name.toLowerCase());

              // SISIPKAN ICON PADA ARRAY
              this.categories[i]["cat_icon"] = icon;
            }
            count += 1;
          }
          Vue.nextTick(function() {
            let data = response.data.data;

            for (let i = 0; i < data.length; i++) {
              if (data[i].brand.length > 6) {
                $("#owl-manufacture-" + i).owlCarousel({
                  lazyLoad: true,
                  loop: true,
                  margin: 10,
                  nav: true,
                  navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
                  autoplay: true,
                  autoplayHoverPause: true,
                  responsive: {
                    0: {
                      items: 2,
                    },
                    600: {
                      items: 3,
                    },
                    1000: {
                      items: 6,
                    },
                  },
                });
              } else {
                $("#owl-manufacture-" + i).owlCarousel({
                  lazyLoad: true,
                  loop: false,
                  margin: 10,
                  nav: true,
                  navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
                  autoplay: true,
                  autoplayHoverPause: true,
                  responsive: {
                    0: {
                      items: 2,
                    },
                    600: {
                      items: 3,
                    },
                    1000: {
                      items: 6,
                    },
                  },
                });
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });

    // REKOMENDASI PRODUK
    window.axios
      .get("/home/tagged-product")
      .then((response) => {
        this.best_product = response.data.data.best_product;
      })
      .catch((error) => {
        console.log(error);
      });

    Vue.nextTick(function() {
      $(".owl-top-carousel-2").owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: 3,
          },
          600: {
            items: 4,
          },
          1000: {
            items: 4,
          },
        },
      });
    });
  },
};
</script>
