<template>
    <div>
      	<div class="row mx-md-4">
			
			<!-- POJOK UMKM -->
			<div class="col-md-12 mb-4" id="pojok-umkm" v-if="pojok_umkm.length > 0">
				<div class="row round shadow py-0 px-3 m-0 bg-white d-flex align-items-center">
					<div class="col-lg-3 offset-lg-0 col-sm-8 offset-sm-2 px-2 py-3">
						<router-link to="/product-list?type=product&q=pojok umkm" class="text-decoration-none">
							<img src="@/assets/images/umkm-go-online.jpg" @error="imageLoadError" class="card-img-top round shadow border" alt="XYZ" />
						</router-link>
					</div>
					<div class="col-lg-9 px-1 py-4">
						<div class="row m-0">
                			<!-- <div class="col-md-12">
                			    <h5 class="mt-2 mb-3"><i class="fa fa-store fa-sm mr-2"></i> Pojok UMKM</h5>
                			</div> -->
							<div class="col-md-12 p-0">
								<div class="label-category mb-3 w-100 d-inline-flex justify-content-between align-items-center" style="background-color: royalblue;">
									<h5 class="my-2 text-light"><i class="fa fa-store fa-sm mr-2"></i>Pojok UMKM</h5>
									<router-link to="/product-list?type=umkm" class="d-none d-sm-block d-md-block d-lg-block">
										<button class="btn btn btn-outline-light"><small>Lihat lebih banyak</small></button>
									</router-link>
								</div>
							</div>
							<div class="col-lg-4 col-6 px-0" v-for="product in pojok_umkm" :key="product.prod_id">
									<router-link :to="'/product-detail/'+product.prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
										<div class="card product mx-2 mb-3">
											<div class="row no-gutters d-flex align-items-center">
												<div class="col-md-4">
													<img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top" :alt="product.prod_image" />
												</div>
												<div class="col-md-8">
													<div class="card-body p-2">
														<h6 class="card-text text-dark mb-0 d-flex align-items-center" style="height: 40px; overflow: hidden;">{{ product.prod_name | str_limit(25) | capitalize }}</h6>
														<p class="card-text text-secondary mb-0 text-truncate">{{ product.prod_summary }}</p>
														<div class="d-inline-flex justify-content-center align-items-center" v-for="index in 5" :key="index">
															<i class="fa fa-star fa-sm text-warning" v-if="index <= product.prod_rating"></i>
															<div class="fa-stack fa-sm" style="height: 16px; line-height: 22px; width: 15px;" v-else-if="index - 0.5 <= product.prod_rating">
																<i class="fa-stack-1x fa fa-star text-secondary"></i>						
																<i class="fa-stack-1x fa fa-star-half text-warning"></i>						
															</div>
															<i class="fa fa-star fa-sm text-secondary" v-else></i>
														</div>
														<!-- <div class="" v-if="product.prod_discount">
															<p class="text-success mb-0">{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency('IDR') }}</p>
															<label class="d-flex justify-content-start align-items-center">
																<small class="bg-danger text-white round px-2">{{ product.prod_discount }}%</small> 
																<strike class="text-muted pl-1">{{ product.prod_price | formatCurrency('IDR') }}</strike>
															</label>
														</div>
														<p class="text-success my-3" v-else>{{ product.prod_price | formatCurrency('IDR') }}</p> -->
														<p class="text-success my-3">-</p>
													</div>
												</div>
											</div>
										</div>
									</router-link>
								</div>
                			<!-- <div class="col-md-12">
								<div class="carousel-wrap w-100">
									<div class="text-center my-3" v-if="isLoading">
    								  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    								</div>
									<div class="owl-carousel owl-product-card pt-0 pb-3 px-2">
										<div class="item card product" v-for="product in pojok_umkm" :key="product.prod_id">
											<router-link :to="'/product-detail/'+product.prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">	
												<div class="d-flex align-items-center" style="height: 175px; overflow: hidden;">
													<img :data-src="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top owl-lazy" :alt="product.prod_image" />
												</div>
												<div class="card-body p-2 text-center">
												
													<div class="d-inline-flex justify-content-center align-items-center" v-for="index in 5" :key="index">
														<i class="fa fa-star fa-sm text-warning" v-if="index <= product.prod_rating"></i>
														<div class="fa-stack fa-sm" style="height: 16px; line-height: 22px; width: 15px;" v-else-if="index - 0.5 <= product.prod_rating">
															<i class="fa-stack-1x fa fa-star text-secondary"></i>						
															<i class="fa-stack-1x fa fa-star-half text-warning"></i>						
														</div>
														<i class="fa fa-star fa-sm text-secondary" v-else></i>
													</div>
													
													<p class="card-text text-dark mb-0 d-flex justify-content-center" style="height:50px; overflow: hidden;">{{ product.prod_name | str_limit(20) | capitalize }}</p>
													<p class="text-muted mb-0 text-truncate">{{ product.prod_summary }}</p>
													<div class="" v-if="product.prod_discount">
														<p class="text-success mb-0">{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency('IDR') }}</p>
														<label class="d-flex justify-content-center align-items-center">
															<small class="bg-danger text-white round px-2">{{ product.prod_discount }}%</small> 
															<strike class="text-muted pl-1">{{ product.prod_price | formatCurrency('IDR') }}</strike>
														</label>
													</div>
													<p class="text-success my-3" v-else>{{ product.prod_price | formatCurrency('IDR') }}</p>
												</div>
												<div class="card-footer text-center p-2 text-muted" v-if="product.prod_umkm">
													<i class="fa fa-store fa-sm"></i>
													<small> {{ product.prod_umkm }}</small>
												</div>
											</router-link>
										</div>
										<div class="item pt-5 mt-2">
											<div class="text-center pt-5 mt-2">
												<router-link to="/product-list?type=umkm" class="text-decoration-none">
													<h4 class="fa fa-chevron-right border rounded-circle px-4 py-3 product"></h4>
												</router-link>
											</div>
										</div>
									</div>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			
					
			<div class="col-md-12 mb-4" v-if="isLoading">
				<div class="round shadow bg-white text-center">
					<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
				</div>
			</div>
			<!-- 6 Product / Category -->
			<template v-for="productCategory in home_productCategory" v-else>
				<div class="col-md-12 mb-4" v-if="productCategory.product.length != 0" :key="productCategory.cat_id" :id="productCategory.cat_id">
					<div class="row round shadow py-0 px-3 m-0 bg-white d-flex align-items-center">
						<div class="col-lg-3 offset-lg-0 col-sm-8 offset-sm-2 px-2 py-3">
							<router-link :to="'/product-list?type=category&q='+ productCategory.cat_id +'-'+ productCategory.cat_name.toLowerCase()" class="text-decoration-none">
								<img v-lazy="catagoryImage(productCategory.cat_image)" @error="imageLoadError" class="card-img-top round shadow border" :alt="productCategory.cat_image" />
							</router-link>
						</div>
						<div class="col-lg-9 px-1 py-4 d-flex align-items-center">
							<div class="row m-0 w-100">
								<div class="col-md-12 p-0">
									<div class="label-category mb-3 w-100 d-inline-flex justify-content-between align-items-center" :style="'background-color:'+ productCategory.cat_warna">
										<h5 class="my-2 text-light"><i :class="'fa '+ productCategory.cat_icon +' fa-md mr-2'"></i>{{ productCategory.cat_name | capitalize }}</h5>
										<router-link :to="'/product-list?type=category&q='+ productCategory.cat_id +'-'+ productCategory.cat_name.toLowerCase()" class="d-none d-sm-block d-md-block d-lg-block">
											<button class="btn btn btn-outline-light"><small>Lihat lebih banyak</small></button>
										</router-link>
									</div>
								</div>
								<div class="col-lg-4 col-6 px-0" v-for="product in productCategory.product" :key="product.prod_id">
									<router-link :to="'/product-detail/'+product.prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
										<div class="card product mx-2 mb-3">
											<div class="row no-gutters d-flex align-items-center">
												<div class="col-md-4">
													<img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top" :alt="product.prod_image" />
												</div>
												<div class="col-md-8">
													<div class="card-body p-2">
														<h6 class="card-text text-dark mb-0 d-flex align-items-center" style="height: 40px; overflow: hidden;">{{ product.prod_name | str_limit(25) | capitalize }}</h6>
														<p class="card-text text-secondary mb-0 text-truncate">{{ product.prod_summary }}</p>
														<div class="d-inline-flex justify-content-center align-items-center" v-for="index in 5" :key="index">
															<i class="fa fa-star fa-sm text-warning" v-if="index <= product.prod_rating"></i>
															<div class="fa-stack fa-sm" style="height: 16px; line-height: 22px; width: 15px;" v-else-if="index - 0.5 <= product.prod_rating">
																<i class="fa-stack-1x fa fa-star text-secondary"></i>						
																<i class="fa-stack-1x fa fa-star-half text-warning"></i>						
															</div>
															<i class="fa fa-star fa-sm text-secondary" v-else></i>
														</div>
														<!-- <div class="" v-if="product.prod_discount">
															<p class="text-success mb-0">{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency('IDR') }}</p>
															<label class="d-flex justify-content-start align-items-center">
																<small class="bg-danger text-white round px-2">{{ product.prod_discount }}%</small> 
																<strike class="text-muted pl-1">{{ product.prod_price | formatCurrency('IDR') }}</strike>
															</label>
														</div>
														<p class="text-success my-3" v-else>{{ product.prod_price | formatCurrency('IDR') }}</p> -->
														<p class="text-success my-3">-</p>
													</div>
												</div>
											</div>
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>

			<!-- Floating category -->
			<div class="floatingSideMenu">
				<ul class="list-group list-group-flush side-category-list d-none d-md-block d-lg-block">
					<!-- 
					<li class="list-group-item py-1 px-2 side-category-item text-center">
						<a href="#pojok-umkm" class="text-decoration-none smooth-scroll-spy"><i class="fa fa-store"></i><span class="px-3"> &nbsp; &nbsp;Pojok UMKM &nbsp; &nbsp; </span></a>
					</li>
					-->
					<li class="list-group-item py-1 px-2 side-category-item text-center" v-for="categorName in home_categoryName" :key="categorName.cat_id">
						<a :href="'#'+ categorName.cat_id" class="text-decoration-none smooth-scroll-spy"><i :class="'fa '+ categorName.cat_icon"></i><span class="px-3">{{ categorName.cat_name | capitalize }}</span></a>
					</li>
				</ul>
			</div>
    	</div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'HomeBottom',
  	data () {
 		return {
			pojok_umkm: [],
			home_productCategory: [],
			home_categoryName: [],
 		}
	},
	computed: {
		isLoading() {
			return this.$store.getters.isLoading
      	},
	},
	methods: {
		get_iconCategory(cat_name) {
			if (cat_name == "alat listrik") {
				return 'fa-bolt'
			}
			if (cat_name == "alat listrik") {
				return 'fa-bolt'
			}
			else if(cat_name == "alat bantu") {
				return 'fa-gavel'
			}
			else if(cat_name == "alat teknik") {
				return 'fa-allergies'
			}
			else if(cat_name == "jasa") {
				return 'fa-user-cog'
			}
			else if(cat_name == "makanan & minuman") {
				return 'fa-cookie-bite'
			}
			else if(cat_name == "health care") {
				return 'fa-briefcase-medical'
			}
			else if(cat_name == "kebutuhan rumah") {
				return 'fa-home'
			}
			else if(cat_name == "makanan ringan") {
				return 'fa-ice-cream'
			}
			else if (cat_name == "bahan masakan") {
				return 'fa-cookie-bite'
			}
			else if (cat_name == "safety & accesories") {
				return 'fa-gavel'
			}
			else if (cat_name == "paket sembako dm") {
				return 'fa-cube'
			}
			else if (cat_name == "konsumable") {
				return 'fa-blender'
			}
			else if (cat_name == "kebutuhan kantor") {
				return 'fa-landmark'
			}
			else if (cat_name == "makanan instan & siap saji") {
				return 'fa-cookie'
			}
			else if (cat_name == "accesories listrik") {
				return 'fa-charging-station'
			}
			else if (cat_name == "kabel") {
				return 'fa-project-diagram'
			}
			else if (cat_name == "komponen instalasi") {
				return 'fa-puzzle-piece'
			}
			else if (cat_name == "komponen panel") {
				return 'fa-solar-panel'
			}
			else if (cat_name == "komponen teknik dengan listrik") {
				return 'fa-bolt'
			}
			else if (cat_name == "komponen teknik dengan mesin") {
				return 'fa-hdd'
			}
			else if (cat_name == "komponen teknik dengan tangan") {
				return 'fa-allergies'
			}
			else if (cat_name == "pneumatik") {
				return 'fa-microchip'
			}
			
			return 'fa-gifts'
		},
	},
    created () {
		Vue.nextTick(function(){
			$('.floatingSideMenu').fadeOut();
		})
		
		window.axios.get('/home/umkm')
 		.then((response) => {
			this.pojok_umkm = response.data.data

			Vue.nextTick(function(){
				$('.owl-product-card').owlCarousel({
					lazyLoad: true,
					loop: false,
					margin: 10,
					nav: false,
					autoplay: false,
					autoplayHoverPause: true,
					responsive: {
						0: {
							items: 2
						},
						600: {
							items: 4
						},
						1000: {
							items: 5
						}
					}
				});
    		});
 		})
 		.catch((error) => {
 			console.log(error)
		})

 		window.axios.get('/home/product-category')
 		.then((response) => {
			this.home_productCategory = response.data.data

			let temp = []
			let icon = ''
			let count = 0
			
			for(let i=0; i<response.data.data.length; i++) {
				if(response.data.data[i].product.length != 0 && count < 14) {
					
					icon = this.get_iconCategory(response.data.data[i].cat_name.toLowerCase())
					
					// SISIPKAN ICON PADA ARRAY
					this.home_productCategory[i]['cat_icon'] = icon
					
					temp.push({
						'cat_icon': icon,
						'cat_id': response.data.data[i].cat_id,
						'cat_name': response.data.data[i].cat_name
					})
				}
				
				count += 1
			}
			this.home_categoryName = temp
 		})
 		.catch((error) => {
 			console.log(error)
		})
    },
}
</script>